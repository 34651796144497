<template>
  <div
    class="main-content page_bg_grey blank-aside-js cart-order-history"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="content-margin">
      <banner-section location="shaniv_cart-top"/>
      <div class="cart-content mt-15">
        <h2 class="title fs23 mb-10">
          {{ $t("Order History") }}
        </h2>
        <div class="d-flex justify-content-between">
          <div class="w-100">
            <cart-tab :activeTab='1'></cart-tab>

            <div class="filter-part weight-700">
              <h5 class="weight-700 mb-5">{{ $t("Time filtering") }}</h5>

              <div class="d-flex align-items-center flex-wrap gap-20">
                <div class="flex-column d-flex">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="filter-label">{{ $t("Start Date") }}:</div>
                    <div>
                      <Datepicker
                        ref="start_date"
                        v-model="filter.start_date"
                        :format="dateFormat"
                        class="input tour-person__input cus-datepicker"
                        :language="languages[currentLang]"
                        :clear-button="true"
                      >
                        <span slot="afterDateInput" class="animated-placeholder">
                          <img src="/assets/img/calander.png" alt="" class="calendar-img" @click="$refs.start_date.showCalendar()"/>
                        </span>
                      </Datepicker>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="filter-label">{{ $t("End Date") }}:</div>
                    <div>
                      <Datepicker
                        ref="end_date"
                        v-model="filter.end_date"
                        :format="dateFormat"
                        class="input tour-person__input cus-datepicker"
                        :language="languages[currentLang]"
                        :clear-button="true"
                      >
                        <span slot="afterDateInput" class="animated-placeholder">
                          <img src="/assets/img/calander.png" alt="" class="calendar-img" @click="$refs.end_date.showCalendar()"/>
                        </span>
                      </Datepicker>
                    </div>
                  </div>
              </div>
                <div class="flex-column d-flex">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="filter-label">{{ $t("Status Start Date") }}:</div>
                    <div>
                      <Datepicker
                        ref="status_start_date"
                        v-model="filter.status_start_date"
                        :format="dateFormat"
                        class="input tour-person__input cus-datepicker"
                        :language="languages[currentLang]"
                        :clear-button="true"
                      >
                        <span slot="afterDateInput" class="animated-placeholder">
                          <img src="/assets/img/calander.png" alt="" class="calendar-img" @click="$refs.status_start_date.showCalendar()"/>
                        </span>
                    </Datepicker>
                  </div>
                  </div>
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="filter-label">{{ $t("Status End Date") }}:</div>
                    <div>
                      <Datepicker
                        ref="status_end_date"
                        v-model="filter.status_end_date"
                        :format="dateFormat"
                        class="input tour-person__input cus-datepicker"
                        :language="languages[currentLang]"
                        :clear-button="true"
                      >
                        <span slot="afterDateInput" class="animated-placeholder">
                          <img src="/assets/img/calander.png" alt="" class="calendar-img" @click="$refs.status_end_date.showCalendar()"/>
                        </span>
                      </Datepicker>
                    </div>
                  </div>
                </div>
                <div class="flex-wrap row flex-grow-1">
                  <div class="flex-column d-flex flex-fill">
                    <div class="">
                      <button class="primary-btn search_btn" @click="filterOrderHistory()">{{ $t("Search") }}</button>
                    </div>
                    <div>
                      <button class="primary-btn search_btn clear_filter_btn" @click="clearFilter()">{{ $t("Clear filters") }}</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <v-data-table
              :headers="orderHistoryHeaders"
              :items="orders"
              class="cus-table editable-table main-table cart-table"
              item-key="id"
              :expanded.sync="expanded"
            >
              <template v-slot:[`item.company`]="{ item }">
                <div class="d-flex justify-content-center color-orange weight-700">
                  {{ item.company }}
                </div>
              </template>
              <template v-slot:[`item.ORDNAME`]="{ item }">
                <span v-if="item.ORDNAME">
                  {{ item.ORDNAME }}
                </span>
                <span class="color-red8 weight-700" v-else>
                  N/A
                </span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span v-if="item.created_at">
                  {{ formatDateTime(item.created_at) }}
                </span>
                <span class="color-red8 weight-700" v-else>
                  N/A
                </span>
              </template>
              <template v-slot:[`item.DUEDATE`]="{ item }">
                <span v-if="item.DUEDATE">
                  {{ item.DUEDATE | formatDate }}
                </span>
                <span class="color-red8 weight-700" v-else>
                  N/A
                </span>
              </template>
              <template v-slot:[`item.amount_of_items`]="{ item }">
                <div class="d-flex justify-content-center weight-700">
                  {{ item.amount_of_items }}
                </div>
              </template>
              <template v-slot:[`item.order_amount`]="{ item }">
                {{ item.order_amount | formatPrice }}
              </template>
              <template v-slot:[`item.status_name`]="{ item }">
                <span v-if="item.status_name">
                  {{ item.status_name }}
                </span>
                <span class="color-red8 weight-700" v-else>
                  N/A
                </span>
              </template>
              <template v-slot:[`item.status_timestamp`]="{ item }">
                <span v-if="item.status_timestamp">
                  {{ formatDateTime(item.status_timestamp) }}
                </span>
                <span class="color-red8 weight-700" v-else>
                  N/A
                </span>
              </template>
              <template v-slot:[`item.invoicing`]="{ item }">
                <div class="d-flex justify-content-center align-items-center">
                  <img v-for="(order_item, index) in item.ShanivOrderItems" src="/assets/img/icons/download.png" :alt="order_item.company" :title="order_item.company" class="download-icon pointer" />
                </div>
              </template>
              <template v-slot:[`item.payment`]="{ item }">
                <div v-if="item.payment_required">
                  <div v-if="item.paid">
                    <div class="d-flex justify-content-center align-items-center">
                      <div>{{$t('Paid')}}</div>
                      <a v-for="(order_doc, index) in item.ShanivOrderDocs" :key="order_doc.id" :href="order_doc.url" target="_blank">
                        <img src="/assets/img/icons/file-chart-line-light.svg" :alt="order_doc.type" :title="order_doc.type" class="download-icon pointer" />
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <router-link
                      :to="{
                        name: 'OrderPayment',
                        params: { id: item.id },
                      }"
                    >
                      <span>{{$t('Pay')}}</span>
                    </router-link>
                  </div>
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.book_again`]="{ item }">
                <div class="d-flex justify-content-center">
                  <img src="/assets/img/icons/shopping-cart-light.svg" alt="" class="cart-icon pointer" @click="openTempOrderPopup(item)" />
                </div>
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="expand-btn plus pointer" @click="expanded = [item]" v-if="!expanded.includes(item)">
                    <img src="/assets/img/icons/plus-light.svg" alt="">
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="expand-btn minus pointer" @click="expanded = []" v-if="expanded.includes(item)">
                    <img src="/assets/img/icons/times-light-orange.svg" alt="">
                  </div>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="manual-card-edit">
                  <CartTable :tableData="item.ShanivOrderItems" :isEditQty="false"></CartTable>
                </td>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <modal
      :width="550"
      :adaptive="true"
      class="tempOrderPopup CEModal modal-scroll-bar"
      name="tempOrderPopup"
    >
      <button class="button modal_close" @click="closeCartPopup()">
        <img src="/assets/img/close.svg" alt />
      </button>
      <div class="mt-10">
        <h2 class="fs18">
          {{ $t("The current set of cart items will be lost. Save your current cart as a temporary order?") }}
        </h2>
        <div class="d-flex mt-15">
          <button class="primary-btn primary-btn_sm" @click="saveCurrentCart()">{{ $t("Yes") }}</button>
          <button class="primary-btn primary-btn_sm bg-red" @click="noSaveCurrentCart()">{{ $t("No") }}</button>
        </div>
      </div>
    </modal>
    <loading
      :active.sync="createOrderLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CartTable from '../../components/cart/CartTable';
import CartTab from '../../components/cart/CartTab.vue';
import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'CartOrderHistory',
  mixins: [CartMixin],
  components: {
    BannerSection,
    CartTable,
    CartTab
  },
  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
        status_start_date: "",
        status_end_date: ""
      },
      dateFormat: "d MMMM yyyy",
      expanded: [],
      orderHistoryHeaders: [
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: true,
          value: 'business_id',
        },
        {
          text: this.$t('Order Number'),
          align: 'center',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('Order Date'),
          align: 'center',
          sortable: true,
          value: 'created_at',
        },
        // {
        //   text: this.$t('Delivery date'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'DUEDATE',
        // },
        {
          text: this.$t('Quantity of items ordered'),
          align: 'center',
          sortable: true,
          value: 'amount_of_items',
        },
        {
          text: this.$t('Order amount'),
          align: 'center',
          sortable: true,
          value: 'order_amount',
        },
        // {
        //   text: this.$t('Quantity of items provided'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'gift_code',
        // },
        // {
        //   text: this.$t('Final amount'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'used',
        // },
        {
          text: this.$t('Status'),
          align: 'center',
          sortable: true,
          value: 'status_name',
        },
        {
          text: this.$t('Status Date'),
          align: 'center',
          sortable: true,
          value: 'status_timestamp',
        },
        {
          text: this.$t('Invoicing'),
          align: 'center',
          sortable: false,
          value: 'invoicing',
        },
        {
          text: this.$t('Payment'),
          align: 'center',
          sortable: false,
          value: 'payment',
        },
        {
          text: this.$t('Book again'),
          align: 'center',
          sortable: false,
          value: 'book_again',
        },
        {
          text: this.$t('Details'),
          align: 'center',
          sortable: false,
          value: 'details',
        }
      ],
      currentTempOrder: {}
    };
  },
  computed: {
    ...mapState({
      createOrderLoading: (state) => state.cartSystem.createOrderLoading,
      shanivOrder: (state) => state.cartSystem.shanivOrder,
      shanivOrderStatuses: (state) => state.cartSystem.shanivOrderStatuses,
    }),
    ...mapGetters('cartSystem', {
      products: 'products',
      packages: 'packages',
    }),
    cart: function () {
      return this.$store.state.cartSystem.cart;
    },
    orders() {
        let orders = [];

        if (this.shanivOrder && this.shanivOrder.items && this.shanivOrder.items.length) {
            orders = this.shanivOrder.items.map(this.itemPreparation);
        }

        return orders;
    }
  },
  methods: {
    ...mapActions('cartSystem', {
      initCart: 'initCart',
      getShanivOrder: 'getShanivOrder',
      getShanivOrderStatuses: 'getShanivOrderStatuses',
      clearCart: 'clearCart',
      addProductsToCart: 'addProductsToCart',
      saveTempOrder: 'saveTempOrder',
    }),
    calcOrderQty(item) {
      const tempObject = item;
      let num = 0;
      if (!tempObject) {
        return num;
      }
      for (let i in tempObject.ShanivOrderItems) {
        if (tempObject.ShanivOrderItems[i].failed) {
          continue;
        }
        tempObject.ShanivOrderItems[i].ShanivOrderItemPackages.forEach(el => {
          let proNum = 0;
          el.ShanivOrderItemPackageProducts.forEach(pro => {
            proNum += pro.quantity
          })
          num += el.quantity * proNum;
        });
        tempObject.ShanivOrderItems[i].ShanivOrderItemProducts.forEach(el => {
          num += el.quantity;
        });
      }
      return num;
    },
    calcOrderAmount(item) {
      const tempObject = item;
      let price = 0;
      if (!tempObject) {
        return price;
      }
      for (let i in tempObject.ShanivOrderItems) {
        if (tempObject.ShanivOrderItems[i].failed) {
          continue;
        }
        tempObject.ShanivOrderItems[i].ShanivOrderItemPackages.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE;
        });
        tempObject.ShanivOrderItems[i].ShanivOrderItemProducts.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE * (el.qtySteps > 0 ? el.qtySteps : 1);
        });
      }
      return price;
    },
    openTempOrderPopup(item) {
      this.currentTempOrder = JSON.parse(JSON.stringify(item));
      if (Object.keys(this.cart).length != 0) {
        this.$modal.show("tempOrderPopup");
      } else {
        this.addOrderToCart();
        this.$router.push({name: "CartSystem"});
      }
    },
    addOrderToCart() {
      this.currentTempOrder.ShanivOrderItems.forEach(el => {
        for (let i in el.ShanivOrderItemPackages) {
          let tempPackage = el.ShanivOrderItemPackages[i];
          let tempPackageQty = el.ShanivOrderItemPackages[i].quantity;
          // tempPackage.id = el.ShanivOrderItemPackages[i].shaniv_package_id;
          delete tempPackage.quantity;
          this.addProductsToCart({
            product: tempPackage,
            quantity: tempPackageQty,
            company: el.company,
            type: 'packages'
          });
        }
        for (let j in el.ShanivOrderItemProducts) {
          let tempProduct = el.ShanivOrderItemProducts[j];
          let tempProductQty = el.ShanivOrderItemProducts[j].quantity;
          delete tempProduct.quantity;
          this.addProductsToCart({
            product: tempProduct,
            quantity: tempProductQty,
            company: el.company,
            type: 'products'
          });
        }
      })  
    },
    closeCartPopup() {
      this.$modal.hide("tempOrderPopup");
    },
    saveCurrentCart() {
      this.saveTempOrder().then(async (res) => {
        if (res == 'success') {
          await this.clearCart();
          this.addOrderToCart();
          this.$router.push({name: "CartSystem"});
        }
      });
      
      this.closeCartPopup();
    },
    async noSaveCurrentCart() {
      await this.clearCart();
      this.addOrderToCart();
      this.closeCartPopup();
      this.$router.push({name: "CartSystem"});
    },
    statusName(item) {
      let statusName = item.status;
      if (this.shanivOrderStatuses && this.shanivOrderStatuses.length > 0) {
        const statusData = this.shanivOrderStatuses.find(sos => sos.id === item.status);

        if (statusData && statusData.name) {
          statusName = this.$t(`${statusData.name}`);
        }
      }

      let failedOrderItemsCount = item.ShanivOrderItems ? item.ShanivOrderItems.filter(soi => soi.failed).length : 0;
      if (failedOrderItemsCount > 0) {
        statusName += ` (${this.$t('partly')})`;
      }

      return statusName;
    },
    formatDateTime(datetime) {
      const date = moment(datetime);

      return date ? date.format('DD/MM/YYYY HH:mm:ss') : datetime;
    },
    filterOrderHistory() {
      let filters = this.filter;
      for (let filter_name in filters) {
        if (filter_name.includes('date') && filters[filter_name]) {
          filters[filter_name] = moment(filters[filter_name]).format('YYYY-MM-DD');

          if (filter_name.includes('start')) {
            filters[filter_name] += 'T00:00:00Z';
          }
          if (filter_name.includes('end')) {
            filters[filter_name] += 'T23:59:59Z';
          }
        }
      }
      this.getShanivOrder({filters: filters});
    },
    clearFilter() {
        for (let filter_name in this.filter) {
            this.filter[filter_name] = '';
        }
        this.getShanivOrder();
    },
    itemPreparation(item, index, arr) {
        item.amount_of_items = this.calcOrderQty(item);
        item.order_amount = this.calcOrderAmount(item);
        item.status_name = this.statusName(item);
        item.payment_required = false;
        item.paid = false;

        if (item.ShanivOrderItems && item.ShanivOrderItems.length) {
            let payment_required_count = 0;
            let paid_count = 0;

            item.ShanivOrderItems.forEach(soi => {
               if (soi.payment_required) payment_required_count++;
               if (soi.payment_required && soi.paid) paid_count++;
            });

            if (payment_required_count > 0) {
                item.payment_required = true;
            }

            if (payment_required_count > 0 && payment_required_count == paid_count) {
                item.paid = true;
            }
        }

        return item;
    }
  },
  created() {
    this.initCart();
    this.getShanivOrder();
    if (!this.shanivOrderStatuses || this.shanivOrderStatuses.length == 0) {
      this.getShanivOrderStatuses();
    }
  },
};
</script>

<style lang="scss">
.cart-order-history {
    .cus-datepicker {
        width: 200px !important;
        input {
            width: 200px !important;
        }
    }
}
span.vdp-datepicker__clear-button {
    position: absolute !important;
    left: calc(100% + 5px);
    right: unset;
    top: 0;
    color: red;
    z-index: 1;
    cursor: pointer;
}
.tempOrderPopup {
    .modal_close {
        position: absolute;
        top: 8px;
        right: 10px;
        img {
            width: 15px;
        }
    }
    .vm--modal {
        padding: 20px;
    }
}
.rtl-type {
    span.vdp-datepicker__clear-button {
        left: unset;
        right: calc(100% + 5px);
    }
}
</style>

<style lang="scss" scoped>
@import '../../assets/scss/_cart';
tr.payment-required td {
  background-color: lightyellow;
}
.download-icon {
    margin: 0 3px;
}
.filter-part {
    margin: 20px 50px;
    padding: 10px 15px;
    .filter-label {
        height: 30px;
        line-height: 30px;
    }
    .input {
        height: 30px;
        padding: 0 10px;
        border-radius: 8px;
    }
    .calendar-img {
        top: 0;
        width: 25px;
        position: absolute;
        right: 8px;
        left: unset;
    }
    .v-input__control {
        height: 100%;
    }
    [class*="col-"] {
        padding: 0 !important;
    }
    .row {
        margin: 0;
    }
    .filter-search_icon {
        position: absolute;
        left: 14px;
        width: 20px;
        top: 5px;
    }
    .d-flex, .row {
        gap: 10px;
    }
    .gap-20 {
        gap: 20px;
    }
}
.search_btn {
    height: 30px;
    width: 100%;
    margin: 0 auto;
    min-width: 200px;
}
.rtl-type {
    .filter-part {
        .calendar-img {
            left: 8px;
            right: unset;
        }
    }
}
</style>